import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { paddings, margins } from 'constants/spacing';
import useLottie from 'hooks/use-lottie';

import animationData1 from './data/lottie-data-1.json';
import animationData2 from './data/lottie-data-2.json';
import animationData3 from './data/lottie-data-3.json';
import animationData4 from './data/lottie-data-4.json';
import animationData5 from './data/lottie-data-5.json';
import animationData6 from './data/lottie-data-6.json';

const Advantages = ({ title, description, items, paddingTop, paddingBottom, marginTop }) => {
  const [animationVisibilityRef, isInView] = useInView({
    threshold: [0.2, 0],
  });

  const { animation: animation1, animationRef: animationRef1 } = useLottie({
    lottieOptions: {
      animationData: animationData1,
      loop: true,
    },
    isInView,
  });

  const { animation: animation2, animationRef: animationRef2 } = useLottie({
    lottieOptions: {
      animationData: animationData2,
      loop: true,
    },
    isInView,
  });

  const { animation: animation3, animationRef: animationRef3 } = useLottie({
    lottieOptions: {
      animationData: animationData3,
      loop: true,
    },
    isInView,
  });

  const { animation: animation4, animationRef: animationRef4 } = useLottie({
    lottieOptions: {
      animationData: animationData4,
      loop: true,
    },
    isInView,
  });

  const { animation: animation5, animationRef: animationRef5 } = useLottie({
    lottieOptions: {
      animationData: animationData5,
      loop: true,
    },
    isInView,
  });

  const { animation: animation6, animationRef: animationRef6 } = useLottie({
    lottieOptions: {
      animationData: animationData6,
      loop: true,
    },
    isInView,
  });

  const animationRefs = useMemo(
    () => [
      { animation: animation1, animationRef: animationRef1 },
      { animation: animation2, animationRef: animationRef2 },
      { animation: animation3, animationRef: animationRef3 },
      { animation: animation4, animationRef: animationRef4 },
      { animation: animation5, animationRef: animationRef5 },
      { animation: animation6, animationRef: animationRef6 },
    ],
    [
      animation1,
      animationRef1,
      animation2,
      animationRef2,
      animation3,
      animationRef3,
      animation4,
      animationRef4,
      animation5,
      animationRef5,
      animation6,
      animationRef6,
    ]
  );

  return (
    <section
      className={classNames(
        'text-primary-white bg-primary-black',
        paddings.top[paddingTop],
        paddings.bottom[paddingBottom],
        margins.top[marginTop]
      )}
    >
      <Container>
        <Heading tag="h2" size="lg" theme="primary-white">
          {title}
        </Heading>
        <p className="max-w-[904px] text-xl font-semibold mt-6 xl:max-w-[782px] xl:mt-4 xl:text-lg md:text-base">
          {description}
        </p>
        <ul
          className="grid grid-cols-3 mt-20 gap-y-20 gap-x-8 xl:mt-16 xl:gap-x-7 xl:gap-y-16 lg:grid-cols-2 lg:gap-x-4 md:block md:space-y-9 md:mt-9"
          ref={animationVisibilityRef}
        >
          {items.map(({ title, description }, index) => (
            <li className="max-w-[280px] xl:max-w-[240px] lg:max-w-none" key={index}>
              <div className="w-12 h-12" ref={animationRefs[index].animationRef} aria-hidden />
              <Heading className="mt-5 opacity-90 xl:mt-4" tag="h3" size="sm" theme="primary-white">
                {title}
              </Heading>
              <p
                className="mt-4 with-link-primary-white xl:mt-3"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

Advantages.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  paddingTop: PropTypes.oneOf(Object.keys(paddings.top)),
  paddingBottom: PropTypes.oneOf(Object.keys(paddings.bottom)),
  marginTop: PropTypes.oneOf(Object.keys(margins.top)),
};

Advantages.defaultProps = {
  paddingTop: null,
  paddingBottom: null,
  marginTop: null,
};

export default Advantages;
