/* eslint-disable react/prop-types */

import classNames from 'classnames';
import React from 'react';
import { BsImageFill as ImageIcon, BsFillFileEarmarkPdfFill as PdfIcon } from 'react-icons/bs';
import { RiBracesFill as BracesIcon } from 'react-icons/ri';

export const Dots = ({ className: additionalClassName }) => (
  <svg
    className={additionalClassName}
    version="1.2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 238 3"
    width="238"
    height="3"
    fill="white"
  >
    <g>
      <path d="m1.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m11.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m21.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m31.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m42.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m52.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m62.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m72.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m83.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m93.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m103.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m113.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m124.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m134.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m144.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m154.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m165.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m175.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m185.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m195.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m206.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m216.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m226.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
      <path d="m236.5 3c-0.8 0-1.5-0.7-1.5-1.5 0-0.8 0.7-1.5 1.5-1.5 0.8 0 1.5 0.7 1.5 1.5 0 0.8-0.7 1.5-1.5 1.5z" />
    </g>
  </svg>
);

// Mind these absolutely positioned elements
export const ImgIcon = ({ className: additionalClassName }) => (
  <ImageIcon className={classNames('h-48 w-full mt-[25%] hero-fadeable', additionalClassName)} />
);

export const PDFIcon = ({ className: additionalClassName }) => (
  <PdfIcon className={classNames('h-48 w-full -mt-[55%] hero-fadeable', additionalClassName)} />
);

export const JsonIcon = ({ className: additionalClassName }) => (
  <BracesIcon className={classNames('h-48 w-full -mt-[60%] hero-fadeable', additionalClassName)} />
);
