import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import PricingFaq from 'components/shared/price-faq';
import PricingCard from 'components/shared/pricing-card';
import cloudPrices from 'constants/pricing';
import { paddings } from 'constants/spacing';

const Pricing = ({ title, description }) => (
  <section className={classNames('pt-20 xl:pt-16 md:pt-10 bg-grey-5', paddings.bottom.lg)}>
    <Container>
      <div className="text-center">
        <Heading tag="h1" size="xl" theme="primary-black">
          {title}
        </Heading>
        <p className="text-lg mt-7 sm:mt-5 xl:text-base mb-12">{description}</p>
      </div>
      <ul
        className="grid grid-cols-3 mt-24 gap-x-8 md:mt-20 xl:gap-x-7 lg:flex-col lg:gap-y-4 align-center justify-center md:items-center lg:items-center "
        style={{
          display: 'flex',
        }}
      >
        {cloudPrices.map((item, index) => (
          <PricingCard className={item.isPopular ? 'lg:order-first' : null} {...item} key={index} />
        ))}
      </ul>
      <div className="text-center mt-12 flex flex-col items-center">
        <Heading tag="h1" size="md" theme="primary-black">
          What are &quot;units&quot;?
        </Heading>
        <p className="text-lg mt-7 sm:mt-5 xl:text-base mb-4 max-w-4xl">
          A Unit is a block of browser time of up to 30s. This typically means a unit is used per
          page, but longer running automations may use more than one.
        </p>
        <p className="text-lg mt-2 sm:mt-1 xl:text-base mb-12 max-w-4xl">
          Units are also how we charge for our proxies, at a rate of 6 units per MB of traffic.
        </p>
      </div>
      <PricingFaq className="!mt-24 !mb-[-5em] md:!mb-[-3em]" />
    </Container>
  </section>
);

Pricing.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      note: PropTypes.string,
      buttonText: PropTypes.string,
      buttonUrl: PropTypes.string,
      buttonTheme: PropTypes.oneOf([
        'primary-white-filled',
        'primary-black-filled',
        'primary-black-outline',
      ]).isRequired,
      items: PropTypes.string.isRequired,
      theme: PropTypes.oneOf(['primary-white', 'primary-black']).isRequired,
      isPopular: PropTypes.bool,
      isEnterprise: PropTypes.bool,
    })
  ).isRequired,
};

export default Pricing;
