import classNames from 'classnames';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { margins } from 'constants/spacing';

import Arrow from './images/arrow.inline.svg';

const itemContentVariants = {
  from: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
  },
  to: {
    opacity: 1,
    height: 'auto',
    marginBottom: 32,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
  exit: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
};

const FAQ = ({ items, bottomText, marginTop }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  return (
    <section className={margins.top[marginTop]}>
      <Container>
        <div className="max-w-[800px] mx-auto">
          <ul>
            {items.map(({ title, text }, index) => {
              const isActive = activeItemIndex === index;
              const handleHeaderClick = () => {
                if (activeItemIndex === index) {
                  setActiveItemIndex(null);
                } else {
                  setActiveItemIndex(index);
                }
              };

              return (
                <li className="border-b border-b-grey-10" key={index}>
                  <div
                    className="flex flex-row items-center justify-between py-5 outline-none cursor-pointer group xl:py-4"
                    role="button"
                    tabIndex="0"
                    onClick={handleHeaderClick}
                    onKeyDown={handleHeaderClick}
                  >
                    <Heading
                      className="mr-4 transition-colors duration-200 group-hover:text-secondary-pink-hover"
                      tag="h3"
                      size="sm"
                      theme="primary-black"
                    >
                      {title}
                    </Heading>
                    <Arrow
                      className={classNames('transition-all duration-500 shrink-0', {
                        'rotate-180 text-secondary-blue': isActive,
                      })}
                    />
                  </div>
                  <motion.div
                    className="space-y-4 text-lg max-w-[760px] overflow-hidden with-link-primary-black xl:text-base xl:space-y-3"
                    variants={itemContentVariants}
                    initial="from"
                    animate={isActive ? 'to' : 'exit'}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </li>
              );
            })}
          </ul>
          <p
            className="mt-8 font-bold with-link-primary-deep-blue"
            dangerouslySetInnerHTML={{ __html: bottomText }}
          />
        </div>
      </Container>
    </section>
  );
};

FAQ.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  bottomText: PropTypes.string.isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)).isRequired,
};

export default FAQ;
