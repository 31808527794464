import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { margins } from 'constants/spacing';

const Achievements = ({ title, description, items, marginTop }) => (
  <section className={classNames('py-36 xl:py-24 md:py-14 bg-grey-5', margins.top[marginTop])}>
    <Container>
      <Heading tag="h2" size="lg" theme="primary-black" className="max-w-[800px] xl:max-w-[640px]">
        {title}
      </Heading>
      <p className="mt-5 sm:mt-3 text-lg max-w-[620px] xl:text-base">{description}</p>
      <ul className="grid grid-cols-3 mt-10 text-center md:mt-5 gap-x-8 xl:gap-x-7 lg:gap-x-4 md:block md:space-y-4">
        {items.map(({ value, title, description }, index) => (
          <li
            className="px-5 pt-8 pb-10 border bg-primary-white border-grey-20 lg:px-3 lg:pt-4 lg:pb-5"
            key={index}
          >
            <span
              className="font-bold text-8xl lg:text-7xl"
              style={{
                background: '-webkit-linear-gradient(45deg, #f39 20%, #ff3 80%)',
                webkitBackgroundClip: 'text',
                webkitTextFillColor: 'transparent',
              }}
            >
              {value}
            </span>
            <Heading className="mt-5 lg:mt-3" tag="h3" size="md" theme="primary-black">
              {title}
            </Heading>
            <p
              className="mt-5 text-lg lg:mt-3 with-link-primary-deep-blue xl:text-base"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </li>
        ))}
      </ul>
    </Container>
  </section>
);

Achievements.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)),
};

Achievements.defaultProps = {
  marginTop: null,
};

export default Achievements;
