import classNames from 'classnames';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import * as sessionParams from 'constants/sessionStorage';
import { setParam } from 'utils/api';

const styles = {
  base: {
    wrapper:
      'relative flex flex-col items-center py-10 px-8 sm:py-5 sm:px-4 border w-[385px] xl:!px-4',
  },
  theme: {
    'primary-black': {
      wrapper: 'text-primary-white bg-primary-black border-primary-black',
    },
    'primary-white': {
      wrapper: 'text-primary-black bg-primary-white border-grey-20',
    },
  },
};

const PricingCard = ({
  className: additionalClassName,
  title,
  price,
  note,
  buttonText,
  buttonUrl,
  planType,
  buttonTheme,
  items,
  theme,
  isPopular,
  isEnterprise,
}) => {
  const handleButtonClick = (e) => {
    e.preventDefault();
    setParam(sessionParams.tier, planType);
    navigate(e.target.href);
  };

  return (
    <li
      className={classNames(styles.base.wrapper, styles.theme[theme].wrapper, additionalClassName)}
    >
      {isPopular && (
        <span className="absolute top-0 flex items-center justify-center h-10 text-sm font-black tracking-wide uppercase -translate-y-full rounded-t -left-px -right-px text-primary-black bg-browserless-gradient">
          Popular option
        </span>
      )}
      <h2 className="text-xl font-bold leading-none">{title}</h2>
      <div className="relative mt-8 align-center sm:mt-4">
        {!isEnterprise && (
          <>
            {!Number.isNaN(Number(price)) && (
              <span className="absolute text-2xl font-semibold top-2 -left-5 md:text-xl md:top-1 md:-left-4">
                $
              </span>
            )}
          </>
        )}
        <span
          className={classNames('font-bold leading-none', {
            'text-7xl md:text-6xl': !isEnterprise,
            'block -mb-5 h-[96px] text-6xl md:h-auto md:text-5xl': isEnterprise,
          })}
        >
          {isEnterprise ? (
            <>{price}</>
          ) : (
            <>
              {price} {Number(price) > 0 && <small className="text-xl -ml-4">/mo</small>}
            </>
          )}
        </span>
      </div>
      <p
        className={classNames(
          'mt-4 text-lg font-bold leading-none with-highlighted-text-secondary-pink sm:mt-2 xl:text-base !text-center',
          isEnterprise ? 'h-[3.85em]' : 'h-[4em]'
        )}
        dangerouslySetInnerHTML={{ __html: note }}
      />{' '}
      <Button
        className="w-full mt-6 sm:mt-3"
        to={buttonUrl}
        size="md"
        theme={buttonTheme}
        onClick={planType && handleButtonClick}
      >
        {buttonText}
      </Button>
      <ul className="self-start mt-10 space-y-4 sm:mt-5 sm:space-y-2 plan-descriptor">
        {items.map((item, index) => (
          <li
            className={classNames(
              'relative pl-4 before:absolute before:top-2 before:left-0 before:w-1.5 before:h-1.5 before:rounded',
              isPopular ? 'before:bg-secondary-yellow' : 'before:bg-secondary-pink'
            )}
            key={index}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </ul>
    </li>
  );
};

PricingCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  note: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  buttonTheme: PropTypes.oneOf([
    'primary-white-filled',
    'primary-black-filled',
    'primary-black-outline',
  ]).isRequired,
  items: PropTypes.string.isRequired,
  planType: PropTypes.oneOf(['180k', '500k']),
  theme: PropTypes.oneOf(['primary-white', 'primary-black']).isRequired,
  isPopular: PropTypes.bool,
  isEnterprise: PropTypes.bool,
};

PricingCard.defaultProps = {
  className: null,
  price: null,
  note: null,
  planType: null,
  isPopular: false,
  isEnterprise: false,
};

export default PricingCard;
