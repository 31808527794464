import classNames from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Image from 'components/shared/image';
import Link from 'components/shared/link';
import { margins } from 'constants/spacing';

import automationIllustration from './images/automation-illustration.svg';
import pdfApiIllustration from './images/pdf-api-illustration.svg';
import performanceApiIllustration from './images/performance-api-illustration.svg';
import scrapingIllustration from './images/scraping-illustration.svg';
import screencastApiIllustration from './images/screencast-api-illustration.svg';
import screenshotApiIllustration from './images/screenshot-api-illustration.svg';

const SectionWithIllustration = ({
  title,
  description,
  linkText,
  linkUrl,
  illustrationName,
  illustrationPosition,
  marginTop,
}) => {
  const {
    scrapingIllustrationAsset1,
    automationIllustrationAsset1,
    pdfApiIllustrationAsset1,
    screenshotApiIllustrationAsset1,
    screencastApiIllustrationAsset1,
  } = useStaticQuery(graphql`
    query {
      scrapingIllustrationAsset1: file(
        relativePath: { eq: "shared/section-with-illustration/scraping-illustration-asset-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 208)
        }
      }
      automationIllustrationAsset1: file(
        relativePath: { eq: "shared/section-with-illustration/automation-illustration-asset-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 160)
        }
      }
      pdfApiIllustrationAsset1: file(
        relativePath: { eq: "shared/section-with-illustration/pdf-api-illustration-asset-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 96)
        }
      }
      screenshotApiIllustrationAsset1: file(
        relativePath: {
          eq: "shared/section-with-illustration/screenshot-api-illustration-asset-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 312)
        }
      }
      screencastApiIllustrationAsset1: file(
        relativePath: {
          eq: "shared/section-with-illustration/screencast-api-illustration-asset-1.jpg"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 308)
        }
      }
    }
  `);

  const illustrations = {
    'how-it-works': {
      className: 'max-w-[534px] xl:max-w-[410px]',
    },
    scraping: {
      className: 'max-w-[456px] xl:max-w-[410px]',
      illustrationProps: {
        canvasClassName: 'z-[-1]',
        width: 456,
        height: 281,
        imageSrc: scrapingIllustration,
        gatsbyImage: scrapingIllustrationAsset1,
        gatsbyImageWidth: 208,
        gatsbyImageHeight: 208,
        gatsbyImageX: 248,
        gatsbyImageY: 35,
      },
    },
    automation: {
      className: 'max-w-[520px] xl:max-w-[410px]',
      illustrationProps: {
        width: 520,
        height: 326,
        imageSrc: automationIllustration,
        gatsbyImage: automationIllustrationAsset1,
        gatsbyImageWidth: 160,
        gatsbyImageHeight: 160,
        gatsbyImageX: 180,
        gatsbyImageY: 78,
      },
    },
    'performance-api': {
      className: 'max-w-[520px] xl:max-w-[410px]',
      illustrationProps: {
        width: 520,
        height: 266,
        imageSrc: performanceApiIllustration,
      },
    },
    'pdf-api': {
      className: 'max-w-[520px] xl:max-w-[410px]',
      illustrationProps: {
        width: 520,
        height: 266,
        imageSrc: pdfApiIllustration,
        gatsbyImage: pdfApiIllustrationAsset1,
        gatsbyImageWidth: 96,
        gatsbyImageHeight: 96,
        gatsbyImageX: 298,
        gatsbyImageY: 10,
      },
    },
    'screenshot-api': {
      className: 'max-w-[520px] xl:max-w-[410px]',
      illustrationProps: {
        canvasClassName: 'z-[-1]',
        width: 520,
        height: 266,
        imageSrc: screenshotApiIllustration,
        gatsbyImage: screenshotApiIllustrationAsset1,
        gatsbyImageWidth: 312,
        gatsbyImageHeight: 210,
        gatsbyImageX: 104,
        gatsbyImageY: 28,
      },
    },
    'screencast-api': {
      className: 'max-w-[520px] xl:max-w-[410px]',
      illustrationProps: {
        width: 520,
        height: 266,
        imageSrc: screencastApiIllustration,
        gatsbyImage: screencastApiIllustrationAsset1,
        gatsbyImageWidth: 308,
        gatsbyImageHeight: 182,
        gatsbyImageX: 106,
        gatsbyImageY: 54,
      },
    },
  };

  const illustrationComponent =
    illustrationName === 'how-it-works' ? (
      <>
        <video autoPlay muted loop>
          <source src="/animation.mp4" type="video/mp4" />
        </video>
      </>
    ) : (
      <Image
        className={classNames(
          'w-full lg:mt-9 lg:mx-auto',
          illustrations[illustrationName].className
        )}
        {...illustrations[illustrationName].illustrationProps}
      />
    );

  return (
    <section className={margins.top[marginTop]}>
      <Container>
        <div className="flex items-center justify-between lg:flex-col lg:items-start">
          {illustrationPosition === 'left' && illustrationComponent}
          <div
            className={classNames('max-w-[520px] xl:max-w-[458px] lg:max-w-none', {
              'mr-18 xl:mr-0 lg:order-first': illustrationPosition === 'left',
            })}
          >
            <Heading tag="h2" size="lg" theme="primary-black">
              {title}
            </Heading>
            <div
              className="mt-6 space-y-5 text-lg xl:mt-4 xl:space-y-4 xl:text-base with-link-primary-black"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {illustrationName === 'how-it-works' && (
              <div className="lg:flex lg:justify-center">
                <Button
                  className="mt-9 lg:mt-6 lg:mb-14 "
                  to="/pricing"
                  size="md"
                  theme="primary-black-filled"
                >
                  See Our Tiers
                </Button>
              </div>
            )}
            {illustrationName !== 'how-it-works' && (
              <>
                {' '}
                <Link
                  className="mt-7 md:mt-6"
                  to={linkUrl}
                  target="_blank"
                  size="md"
                  theme="primary-deep-blue"
                  withArrow
                >
                  {linkText}
                </Link>
              </>
            )}
          </div>
          {illustrationPosition === 'right' && illustrationComponent}
        </div>
      </Container>
    </section>
  );
};

SectionWithIllustration.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  illustrationName: PropTypes.oneOf([
    'how-it-works',
    'scraping',
    'automation',
    'performance-api',
    'pdf-api',
    'screenshot-api',
    'screencast-api',
  ]).isRequired,
  illustrationPosition: PropTypes.oneOf(['left', 'right']).isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)).isRequired,
};

export default SectionWithIllustration;
