import parse, { attributesToProps } from 'html-react-parser';
import isBoolean from 'lodash.isboolean';
import isEmpty from 'lodash.isempty';
import React from 'react';

import Achievements from 'components/pages/about-us/achievements';
import HeroAboutUs from 'components/pages/about-us/hero';
import FAQ from 'components/pages/faq/faq';
import HeroFAQ from 'components/pages/faq/hero';
import Features from 'components/pages/home/features';
import HeroHome from 'components/pages/home/hero';
import TestimonialsHome from 'components/pages/home/testimonials';
import Pricing from 'components/pages/pricing/pricing';
import Advantages from 'components/shared/advantages';
import CodeBlock from 'components/shared/code-block';
import CodeTabs from 'components/shared/code-tabs';
import CTA from 'components/shared/cta';
import Hero from 'components/shared/hero';
import SectionWithIllustration from 'components/shared/section-with-illustration';
import Testimonials from 'components/shared/testimonials';

function isBooleanString(string) {
  return string === 'true' || string === 'false';
}

function isJSON(string) {
  if (typeof string !== 'string') return false;
  if (isBooleanString(string)) return false;

  try {
    JSON.parse(string);
  } catch (error) {
    return false;
  }

  return true;
}

function toCamelCase(string) {
  return string.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

function transformValue(value) {
  if (isJSON(value)) {
    const parsedJSON = JSON.parse(value);

    if (Array.isArray(parsedJSON)) return parsedJSON.map((item) => transformProps(item));
    if (typeof parsedJSON === 'object' && parsedJSON !== null) return transformProps(parsedJSON);

    return parsedJSON;
  }

  if (Array.isArray(value)) return value.map((item) => transformProps(item));
  if (typeof value === 'object' && value !== null) return transformProps(value);

  if (isBooleanString(value)) return value === 'true';

  return value;
}

function transformProps(props) {
  const transformedProps = {};

  Object.keys(props).forEach((propName) => {
    const transformedValue = transformValue(props[propName]);
    if (!transformedValue && isEmpty(transformedValue) && !isBoolean(transformedValue)) return;
    transformedProps[toCamelCase(propName)] = transformedValue;
  });

  return transformedProps;
}

const components = {
  herohome: HeroHome,
  features: Features,
  advantages: Advantages,
  codetabs: CodeTabs,
  sectionwithillustration: SectionWithIllustration,
  testimonialshome: TestimonialsHome,
  testimonials: Testimonials,
  cta: CTA,
  heroaboutus: HeroAboutUs,
  hero: Hero,
  faq: FAQ,
  herofaq: HeroFAQ,
  pricing: Pricing,
  achievements: Achievements,
  codeblock: CodeBlock,
};

const getReactContentWithLazyBlocks = (content) => {
  // https://github.com/remarkablemark/html-react-parser#htmlparser2
  // The library does parsing on client side differently from server side
  // it results in having a need of passing htmlparser2 to adjust behavior
  // according to the client side behavior
  const reactedContent = parse(content, {
    htmlparser2: {
      lowerCaseAttributeNames: true,
    },
    // eslint-disable-next-line consistent-return
    replace: (domNode) => {
      if (domNode.type === 'tag') {
        const Component = components[domNode.name];

        if (!Component) return null;

        const props = transformProps(attributesToProps(domNode.attribs));

        return <Component {...props} />;
      }
    },
  });
  return { reactedContent };
};

export default getReactContentWithLazyBlocks;
