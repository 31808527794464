/* eslint-disable jsx-a11y/anchor-is-valid */

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import { CopyBlock, monokaiSublime } from 'react-code-blocks';
import { IoPlaySharp as PlayIcon } from 'react-icons/io5';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Loader from 'components/shared/loader/loader';
import { pdfDemo, scrapeDemo, screenshotDemo } from 'utils/api';

import Editor, { examples } from './editor';
import { Dots, ImgIcon, JsonIcon, PDFIcon } from './icons';

const Hero = (props) => {
  const { title, buttonText, buttonUrl, subtitle, secondaryButtonText, secondaryButtonUrl } = props;
  const [example, setExample] = useState(examples[0]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isAnimationReady, setAnimationReady] = useState(false);
  const [pageNum, setPageNum] = useState(0); // for PDF demo only
  const [jsonResponse, setJsonResponse] = useState({});
  const [isDocumentReady, setDocumentReady] = useState(false);

  const urlRef = useRef();
  const dlRef = useRef();
  const mobileDlRef = useRef();
  const isPDF = example === examples[1];
  const isJSON = example === examples[2];
  const isExampleImg = example === examples[0] || isPDF;

  const setDownloads = (blob, name) => {
    [dlRef, mobileDlRef].forEach((ref) => {
      const $anchor = ref.current;
      $anchor.href = blob;
      $anchor.download = name;
    });
  };

  const setErrors = () => {
    [dlRef, mobileDlRef].forEach((ref) => {
      const $anchor = ref.current;
      $anchor.removeAttribute('href');
    });
  };

  const handleImageResponse = (blob) => {
    const $img = document.getElementById('demoImg');
    $img.setAttribute('src', blob.toString());

    setDownloads(blob, 'demo.jpg');
    setMessage('Screenshot ready! <u>Click here</u> to download.');
  };

  const handlePdfResponse = async (blob) => {
    const objectURL = URL.createObjectURL(blob);
    setDownloads(objectURL, 'demo.pdf');

    try {
      const data = await blob.arrayBuffer();
      const doc = await window.pdfjsLib.getDocument(data).promise;
      const page = await doc.getPage(1);

      const viewport = page.getViewport({ scale: 1.0 });
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.width = viewport.width;
      canvas.height = viewport.height;
      const renderContext = {
        canvasContext: context,
        viewport,
      };

      await page.render(renderContext).promise;
      const imageDataUrl = canvas.toDataURL('image/jpeg');
      const $img = document.getElementById('demoImg');

      $img.setAttribute('src', imageDataUrl);
      doc.destroy();
      setPageNum(doc.numPages);
    } catch (e) {
      setMessage('There was an error processing that pdf...');
      return;
    }
    setMessage('PDF ready! <u>Click here</u> to download.');
  };

  const handleJsonResponse = (json, blob) => {
    const objectURL = URL.createObjectURL(blob);

    setJsonResponse(json);
    setDownloads(objectURL, 'demo.json');
    setMessage('JSON ready! <u>Click here</u> to download.');
  };

  const runDemo = async () => {
    setIsLoading(true);

    try {
      const res = await (async () => {
        const demoURL = urlRef.current.innerText.replaceAll('"', '');

        if (example === 'screenshot') return screenshotDemo(demoURL);
        if (example === 'pdf') return pdfDemo(demoURL);

        return scrapeDemo(demoURL);
      })();

      if (res.status === 429)
        throw new Error('Too many requests. <a href="/sign-up">Signup for free</a> to continue.');
      if (res.status !== 200) throw new Error(await res.text());
      if (example === 'scrape') {
        handleJsonResponse(await res.clone().json(), await res.blob());
        setAnimationReady(true);
        return;
      }

      const blob = await res.blob();
      const objectURL = URL.createObjectURL(blob);

      if (example === 'screenshot') handleImageResponse(objectURL);
      else if (example === 'pdf') await handlePdfResponse(blob);
      setAnimationReady(true);
    } catch (e) {
      setAnimationReady(false);
      setErrors();
      setMessage(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  // This is a timeout to allow another fade on the #demoImg first
  const resetFades = () => {
    setTimeout(() => {
      setAnimationReady(false);
      setIsLoading(false);
      setMessage('');
    }, 200);
  };

  useEffect(() => {
    const targetNode = document.getElementById('codeWrapper');
    const config = { attributes: true, childList: true, subtree: true };

    const callback = (mutationList, observer) => {
      if (mutationList.find((mu) => mu.target.nodeName === 'CODE')) {
        urlRef.current = document.querySelector('#codeWrapper span:nth-child(9)');
        urlRef.current?.setAttribute('contenteditable', 'true');

        observer.disconnect();
        setDocumentReady(true);
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }, []);

  return (
    <section className="overflow-hidden text-center bg-primary-black pt-15 pb-36 lg:pb-20 2xl:pb-60 md:pt-8">
      <Container className="!max-w-[1216px]">
        <Heading
          className="max-w-[1000px] mx-auto xl:max-w-[945px]"
          tag="h1"
          size="xl"
          theme="primary-white"
        >
          {title}
        </Heading>
        <Heading
          className="mx-auto xl:max-w-[800px] !text-neutral-500 mt-8"
          tag="p"
          size="md"
          theme="primary-white"
        >
          {subtitle}
        </Heading>

        <div className="flex justify-center gap-4">
          <Button
            className="mt-9 lg:mt-6 lg:mb-14 sm:px-12"
            to={buttonUrl}
            size="md"
            theme="primary-white-filled"
          >
            {buttonText}
          </Button>
          <Button
            className="mt-9 lg:mt-6 lg:mb-14 sm:px-12"
            to={secondaryButtonUrl}
            size="md"
            theme="primary-white-outline"
          >
            {secondaryButtonText}
          </Button>
        </div>
        <div
          className={classNames(
            'flex justify-between w-full h-[450px] xl:h-[380px] lg:h-auto sm:h-auto sm:mx-[-75px] xs:mx-[-125px] xl:justify-center pt-10 mt-6',
            'lg:!contents lg:mt-14'
          )}
          aria-hidden
        >
          <Editor
            example={example}
            setExample={setExample}
            onTabChange={() => {
              const $div = document.getElementById('demoImg').parentElement;
              $div.classList.add('opacity-0');
              resetFades();
            }}
          />
          <div className="flex flex-wrap flex-col items-center justify-center xl:mt-[5rem] !h-full lg:hidden">
            <Dots className={isLoading && 'mt-4'} />
            <div
              className={classNames(
                '!w-[1rem] !h-[1rem] bg-pink-gradient rounded-full top-[-0.6rem] -left-1/2 animate-slide',
                isLoading ? 'relative' : 'hidden'
              )}
            />
          </div>
          <div className="w-[30rem] h-[30rem] bg-hero-gradient border-2 border-grey-90 rounded text-grey-90 flex flex-wrap content-center lg:hidden">
            <div
              className={classNames(
                'h-full flex flex-wrap w-full content-between',
                isAnimationReady && isExampleImg && '!content-end'
              )}
            >
              {/* Result placeholder image

                  These, are always rendered, but their opacity changes.
                  This is to allow the fade animations
               */}
              <div className={isAnimationReady && isExampleImg && '!opacity-100'} />
              <ImgIcon
                className={!isAnimationReady && example === 'screenshot' && '!opacity-100'}
              />
              <PDFIcon className={!isAnimationReady && example === 'pdf' && '!opacity-100'} />
              <JsonIcon className={!isAnimationReady && example === 'scrape' && '!opacity-100'} />

              <div
                className={classNames(
                  'w-[17rem] h-[14rem] bg-grey-5 rounded-lg hero-fadeable',
                  'absolute ml-26 mt-24 xl:ml-10',
                  isAnimationReady && '!opacity-100',
                  (isPDF || isJSON) && 'mt-7 h-[22.5rem]',
                  isJSON && 'p-[5px] w-[25rem] ml-10 xl:w-[17.5rem] xl:h-[21rem]'
                )}
              >
                <img
                  alt=""
                  id="demoImg"
                  className={classNames(
                    'mt-[17px] w-[90%] mx-auto border-2 border-grey-80',
                    isJSON && 'hidden'
                  )}
                />
                {isPDF && <p className="text-grey-50 font-semibold">{pageNum} pages</p>}
                {isJSON && (
                  <div id="jsonDemoWrapper">
                    <CopyBlock
                      text={JSON.stringify(jsonResponse, null, 2)}
                      language="js"
                      theme={monokaiSublime}
                      showLineNumbers={false}
                      codeBlock
                      wrapLines
                    />
                  </div>
                )}
              </div>

              <a
                href=""
                ref={dlRef}
                className="text-center w-full text-xl text-white mb-10 px-4 demo-msg-response"
                dangerouslySetInnerHTML={{ __html: message }}
                aria-label="Demo result message"
              />
            </div>
          </div>
        </div>

        <Button
          className={classNames(
            '!w-[6rem] !h-[6rem] bg-pink-gradient text-5xl rounded-full relative top-[-4rem] text-white mt-[-1rem] z-[500]',
            'xl:top-0 xl:mt-[-8%] lg:ml-[calc(100%-7rem)]',
            !isDocumentReady || isLoading ? 'hero-busy' : 'animate-pulse'
          )}
          onClick={runDemo}
        >
          {isLoading ? <Loader /> : <PlayIcon />}
        </Button>
        <span className="hidden text-white font-medium xl:hidden lg:block lg:mt-6 md:text-lg lg:text-xl">
          <a
            ref={mobileDlRef}
            href=""
            className="demo-msg-response"
            target="_blank"
            dangerouslySetInnerHTML={{ __html: message }}
            aria-label="Demo result message"
          />
        </span>
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
};

Hero.defaultProps = {
  subtitle: '',
  secondaryButtonText: '',
  secondaryButtonUrl: '',
};

export default Hero;
