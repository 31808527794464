import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/esm/languages/hljs/bash';
import javascript from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import { paddings, margins } from 'constants/spacing';
import LogoMini from 'images/logo-mini.inline.svg';

import './code-tabs.css';

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('bash', bash);

const itemsPVariants = {
  from: {
    opacity: 0,
    height: 0,
    marginTop: 0,
  },
  to: {
    opacity: 1,
    height: 'auto',
    marginTop: 12,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
  exit: {
    opacity: 0,
    height: 0,
    marginTop: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
};

const itemsCodeAnimationProps = {
  initial: {
    opacity: 0,
    translateY: 60,
  },
  animate: {
    opacity: 1,
    translateY: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 1 },
  },
  exit: {
    opacity: 0,
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.3 },
  },
};

const CodeTabs = ({ title, items, paddingTop, paddingBottom, marginTop }) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0);

  const breakpoints = useBreakpoint();

  return (
    <section
      className={classNames(
        'text-primary-white bg-primary-black overflow-hidden',
        paddings.top[paddingTop],
        paddings.bottom[paddingBottom],
        margins.top[marginTop]
      )}
    >
      <Container className="grid grid-cols-12 gap-x-8 xl:gap-x-7 lg:block">
        <div className="col-span-4 xl:col-span-5">
          <Heading
            className="xl:max-w-[296px] lg:max-w-none"
            tag="h2"
            size="lg"
            theme="primary-white"
          >
            {title}
          </Heading>
          <ul className="relative space-y-9 pl-11 mt-8 before:absolute before:top-2 before:left-2.5 before:w-px before:h-[92.5%] before:bg-grey-80">
            {items.map(({ title, description }, index) => {
              const isActive = activeItemIndex === index;

              return (
                <li key={index}>
                  <Heading
                    className={classNames(
                      'code-tabs-item-heading',
                      { active: isActive },
                      { inactive: !isActive },
                      { yellow: index === 0 || index === 3 },
                      { pink: index === 1 || index === 4 },
                      { blue: index === 2 || index === 5 },
                      { last: index === items.length - 1 }
                    )}
                    tag="h3"
                    size="sm"
                    theme="primary-white"
                    onClick={() => setActiveItemIndex(index)}
                  >
                    {title}
                  </Heading>
                  <motion.p
                    className="with-link-primary-white xl:max-w-[332px]"
                    dangerouslySetInnerHTML={{ __html: description }}
                    variants={itemsPVariants}
                    initial="from"
                    animate={isActive ? 'to' : 'exit'}
                  />
                </li>
              );
            })}
          </ul>

          <div className="lg:flex lg:justify-center">
            <Button className="mt-12" to="/docs/start" size="md" theme="primary-white-filled">
              See The Docs
            </Button>
          </div>
        </div>
        <div className="relative col-span-6 col-start-6 xl:col-span-7 lg:mt-10">
          <AnimatePresence initial={false} exitBeforeEnter>
            {items.map((item, index) =>
              index === activeItemIndex ? (
                <motion.div className="h-full" key={index} {...itemsCodeAnimationProps}>
                  {!breakpoints.md && (
                    <SyntaxHighlighter
                      className="code-tabs-code"
                      language={item.codeLanguage}
                      useInlineStyles={false}
                      showLineNumbers
                    >
                      {item.code.replace(/<br>/g, '\n')}
                    </SyntaxHighlighter>
                  )}
                  {breakpoints.md && (
                    <SyntaxHighlighter
                      className="code-tabs-code"
                      language={item.codeLanguage}
                      useInlineStyles={false}
                    >
                      {item.code.replace(/<br>/g, '\n')}
                    </SyntaxHighlighter>
                  )}
                  <LogoMini className="absolute right-4 bottom-4 sm:hidden" aria-hidden />
                </motion.div>
              ) : null
            )}
          </AnimatePresence>
        </div>
      </Container>
    </section>
  );
};

CodeTabs.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      codeLanguage: PropTypes.string.isRequired,
    })
  ).isRequired,
  paddingTop: PropTypes.oneOf(Object.keys(paddings.top)),
  paddingBottom: PropTypes.oneOf(Object.keys(paddings.bottom)),
  marginTop: PropTypes.oneOf(Object.keys(margins.top)),
};

CodeTabs.defaultProps = {
  paddingTop: null,
  paddingBottom: null,
  marginTop: null,
};

export default CodeTabs;
