import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigation, A11y, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Image from 'components/shared/image';
import { margins } from 'constants/spacing';
import ArrowButton from 'icons/arrow-button.inline.svg';
import Quote from 'icons/quote.inline.svg';

import 'swiper/css';

const Testimonials = ({ title, logos, items, marginTop }) => (
  <section className={classNames('text-center', margins.top[marginTop])}>
    <Container>
      <Heading
        className="max-w-[800px] mx-auto xl:max-w-[620px]"
        tag="h2"
        size="lg"
        theme="primary-black"
      >
        {title}
      </Heading>
      <div className="flex justify-center mt-16 space-x-16 xl:space-x-14 lg:space-x-12 md:mt-10 md:flex-wrap md:space-x-0 md:gap-x-8 md:gap-y-6">
        {logos.map(({ url, alt }, index) => (
          <Image
            className="h-9 xl:h-8"
            imageSrc={url}
            height={36}
            alt={alt}
            loading="lazy"
            key={index}
          />
        ))}
      </div>
      <Quote className="mx-auto mt-16 md:mt-14" />
      <Swiper
        className="max-w-[1008px] mt-8 mx-auto xl:max-w-[782px]"
        modules={[Navigation, A11y, Pagination]}
        pagination={{
          type: 'bullets',
          el: '.testimonials-pagination',
          clickable: true,
        }}
        simulateTouch={false}
        speed={500}
        breakpoints={{
          768: {
            autoHeight: false,
            pagination: false,
            navigation: {
              prevEl: '.testimonials-prev-button',
              nextEl: '.testimonials-next-button',
            },
          },
        }}
        loop
        autoHeight
      >
        {items.map(({ text, author }, index) => (
          <SwiperSlide
            className="flex flex-col justify-center !h-auto"
            tag="blockquote"
            key={index}
          >
            <p className="text-3xl font-normal leading-normal xl:text-2xl xl:leading-normal md:text-xl">
              {text}
            </p>
            <cite
              className="inline-block mt-5 not-italic font-bold with-link-primary-deep-blue xl:mt-4 md:text-sm md:leading-normal"
              dangerouslySetInnerHTML={{ __html: author }}
            />
          </SwiperSlide>
        ))}
        <div className="flex justify-center mt-10 space-x-5 text-primary-black md:mt-7 md:space-x-7 md:hidden">
          <ArrowButton className="transition-colors duration-200 testimonials-prev-button hover:text-grey-40 md:hidden" />
          <ArrowButton className="transition-colors duration-200 rotate-180 testimonials-next-button hover:text-grey-40 md:hidden" />
        </div>
        <div className="!hidden mt-8 testimonials-pagination slider-bullets md:!flex" />
      </Swiper>
    </Container>
  </section>
);

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  logos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    })
  ).isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)).isRequired,
};

export default Testimonials;
