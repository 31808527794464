import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';

import shape1 from './images/shape-1.svg';
import shape2 from './images/shape-2.svg';

const Hero = ({ title, description, buttonText, buttonUrl, linkText, linkUrl }) => (
  <section className="pt-20 text-center xl:pt-16 md:pt-10 xl:overflow-hidden">
    <Container>
      <div className="max-w-[720px] mx-auto">
        <Heading tag="h1" size="xl" theme="primary-black">
          {title}
        </Heading>
        <p className="text-lg mt-7 sm:mt-5 xl:text-base">{description}</p>
        <div className="flex flex-col items-center mt-10 sm:mt-7">
          <Button to={buttonUrl} size="md" theme="primary-black-filled">
            {buttonText}
          </Button>
          <Link className="mt-5" to={linkUrl} target="_blank" size="sm" theme="primary-deep-blue">
            {linkText}
          </Link>
        </div>
      </div>
      <img
        className="absolute -translate-y-1/2 -left-24 top-1/2 2xl:left-0 xl:w-[180px] xl:-left-24 lg:hidden"
        src={shape1}
        alt=""
        aria-hidden
      />
      <img
        className="absolute -translate-y-1/2 top-24 -right-28 2xl:-right-8 xl:w-[180px] xl:top-1/2 xl:-right-24 lg:hidden"
        src={shape2}
        alt=""
        aria-hidden
      />
    </Container>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
};

export default Hero;
