import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Quote from 'icons/quote.inline.svg';

import './hero.css';

const Hero = ({ title, description, founder, content }) => {
  const { illustration } = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "pages/about-us/hero/illustration.png" }) {
        childImageSharp {
          gatsbyImageData(width: 432)
        }
      }
    }
  `);

  return (
    <section className="pt-20 xl:pt-16 md:pt-10">
      <Container>
        <div className="flex items-center pb-16 space-x-12 border-b-4 border-dotted border-grey-30 xl:space-x-6 lg:flex-col lg:space-x-0 md:pb-10">
          <div>
            <Quote />
            <Heading
              tag="h1"
              size="lg"
              theme="primary-black"
              className="mt-5 max-w-[740px] with-highlighed-text-gradient-secondary-pink lg:max-w-none"
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p className="mt-5 sm:mt-3 text-xl font-semibold max-w-[590px] lg:max-w-none xl:text-lg md:text-base">
              {description}
            </p>
          </div>
          <div className="relative lg:mt-8">
            <GatsbyImage image={getImage(illustration)} alt="" loading="eager" aria-hidden />
            <p className="absolute -bottom-1 left-12 xl:left-9 xl:bottom-0 xl:text-sm lg:left-12 xs:left-9 xs:text-xs">
              {founder}
            </p>
          </div>
        </div>
        <div
          className="pt-16 space-y-4 text-xl about-us-content max-w-[1144px] with-link-primary-black xl:text-lg md:text-base md:pt-10"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  founder: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Hero;
