import classNames from 'classnames';
import { motion } from 'framer-motion';
import noop from 'lodash.noop';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Arrow from '../../pages/sign-up/purchase/images/arrow.inline.svg';
import Heading from '../heading';

const AnimatedDiv = motion.div;

const faqs = [
  {
    title: 'I still have questions, can I talk to someone?',
    content:
      'Absolutely! If you still have open questions, feel free to <a rel="noopener" target="_blank" href="/contact">reach out to support@browserless.io</a> and we will be happy to answer any questions for you.',
  },
  {
    title: 'How do unit-based plans work?',
    content:
      'Unit-based plans allow users to subscribe to a plan that covers the number of Units they intend to use in a month. Unit-based plans run on a shared fleet in San Francisco, California and London, England. You can see more about the <a rel="noopener" target="_blank" href="/docs/versions">shared fleet here</a>.',
  },
  {
    title: 'What constitutes a Unit?',
    content:
      'A Unit is any time a browser is started with our API/library and is used for up to 30 seconds. A new Unit is charged every 30 seconds of a running Session. All Sessions are cut off after 15-minutes to avoid charges for errors.',
  },
  {
    title: 'What happens if I go over my unit-based plan?',
    content:
      'If you happen to go over your subscription amount, then overage fees are charged per Unit at the specified overage rate for any paid plans. Free plans will actively reject incoming requests after the threshold is met. Any overage fees are billed whenever overages reach the dollar amount of the subscription the customer is on, when changing plans, or at the end of the month if the billing threshold is not reached.<br /><br />For billing clarity, your invoice will have separate line items for the subscription and any overages. We will send you an email notice anytime you start accumulating overage charges, which happens at most once an hour.',
  },
  {
    title: 'How does the trial work?',
    content:
      `When you sign-up for an account a valid payment method is required. If you decide to cancel within the 7 days, this payment method will be removed and you won't be charged. After 7 days, the trial will expire and you will be automatically charged for the ongoing subscription. You can cancel self-service plans at any time from the Account section in the account portal.`,
  },
  {
    title: 'How do Dedicated plans work?',
    content:
      'Dedicated plans get you your own Worker(s) with no session limits. A Standard Worker can generally handle up to 20 concurrent browser sessions, but this does vary based on the complexity of the task and how large the site is. Customers that have more traffic can purchase multiple Workers. Each worker has 2CPU and 4GB RAM.',
  },
  {
    title: `Can I change plans whenever I'd like to?`,
    content: `Yes! Once you change plans we reset your billing cycle to the time of the change and charge for the full amount of the plan you're moving to. Any overages are also charged at that time. Feel free to see what the cost is on your account page.`,
  },
  {
    title: 'What are the benefits of Dedicated?',
    content:
      'Dedicated plans run only your traffic on your machines only, adding in a layer of security via network isolation. We also have several locations available for Dedicated plans, including London, New York, Amsterdam and San Francisco, making many types of compliances easier to obtain. Finally, all of the browseless APIs are available on Dedicated.',
  },
  {
    title: 'What are the benefits of Dedicated Premium?',
    content:
      'Browserless Premium has advanced features, such as programmatic screen recording with audio, new websocket-based routes for specific use-cases, and can even run up to 10 times faster while using less hardware resources. Premium is only available on a Dedicated or Self-Hosted plan. To learn more, feel free to <a rel="noopener" target="_blank" href="/contact">reach out to sales@browserless.io</a>.',
  },
  {
    title: 'What are the Self-Hosted options?',
    content:
      'Self-hosting Browserless is a license to host the service in your own cloud or on-prem. The Standard and Premium versions of Browserless are both available as self-hosted options. This can be a great option for data-sensitive organizations needing to comply with local laws and other standards.',
  },
  {
    title: 'Are there annual discounts available?',
    content:
      'If this sort of pricing is important to you, please <a rel="noopener" target="_blank" href="/contact">get in touch with sales@browserless.io</a>.',
  },
];

const hoverAnimation =
  'transition-colors duration-200 group-hover:text-secondary-pink-hover cursor-pointer cursor-pointer';

const animation = {
  from: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
    display: 'block',
    transitionEnd: {
      display: 'none',
    },
  },
  to: {
    opacity: 1,
    height: 'auto',
    marginBottom: 32,
    display: 'block',
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
  exit: {
    opacity: 0,
    height: 0,
    marginBottom: 0,
    display: 'block',
    transition: { ease: [0.25, 0.1, 0, 1], duration: 0.5 },
  },
  closed: {
    display: 'none',
  },
};

const PricingFaq = ({ className: additionalClassName, isSinglePage }) => {
  const [faqOpen, setFaqOpen] = useState(isSinglePage);
  const [questionId, setQuestionId] = useState(0);

  const handleFaqClick = () => {
    if (isSinglePage) noop();
    else setFaqOpen(!faqOpen);
  };

  const handleChange = (index) => {
    if (questionId === index) setQuestionId(-1);
    else setQuestionId(index);
  };

  return (
    <div className={classNames('bg-grey-5 text-primary-black mt-[-5em] pb-8', additionalClassName)}>
      <div className="flex justify-center group group">
        <Heading
          className={classNames('text-center', isSinglePage ? 'mb-12' : hoverAnimation)}
          tag={isSinglePage ? 'h1' : 'h3'}
          size={isSinglePage ? 'lg' : 'sm'}
          theme="primary-black"
          onClick={handleFaqClick}
        >
          {isSinglePage ? 'Pricing FAQ' : 'Still have questions?'}
        </Heading>
        {!isSinglePage && (
          <Arrow className={classNames(hoverAnimation, 'mt-2 ml-2', faqOpen && 'rotate-180')} />
        )}
      </div>

      <AnimatedDiv
        className="max-w-[820px] px-4 mt-8 space-y-4 text-lg overflow-hidden with-link-primary-black xl:text-base xl:space-y-3 flex justify-start flex-col mr-auto ml-auto"
        variants={animation}
        initial="from"
        animate={faqOpen ? 'to' : 'exit'}
      >
        {faqs.map(({ title, content }, index) => {
          const isActive = questionId === index;

          return (
            <div className="border-b border-b-grey-30">
              <div
                className="flex justify-between cursor-pointer group"
                role="button"
                tabIndex="0"
                onClick={() => handleChange(index)}
                onKeyDown={({ key }) => {
                  if (key === 'Enter' || key === ' ' || key === 'Escape') handleChange(index);
                }}
              >
                <Heading
                  className={classNames(
                    hoverAnimation,
                    'mr-4 font-bold',
                    isActive && 'text-primary-deep-blue'
                  )}
                  tag="h4"
                  size="xs"
                  theme="primary-black"
                >
                  {title}
                </Heading>
                <Arrow className={isActive && 'rotate-180'} />
              </div>
              <AnimatedDiv variants={animation} initial="from" animate={isActive ? 'to' : 'from'}>
                <p
                  className="mt-4 with-link-primary-pink"
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </AnimatedDiv>
            </div>
          );
        })}
      </AnimatedDiv>
    </div>
  );
};

PricingFaq.propTypes = {
  className: PropTypes.string,
  isSinglePage: PropTypes.bool,
};

PricingFaq.defaultProps = {
  className: '',
  isSinglePage: false,
};

export default PricingFaq;
