import classNames from 'classnames';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigation, A11y, Pagination, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Image from 'components/shared/image';
import { margins } from 'constants/spacing';
import ArrowButton from 'icons/arrow-button.inline.svg';
import Quote from 'icons/quote.inline.svg';

import 'swiper/css';
import 'swiper/css/effect-fade';

const Testimonials = ({ title, items, marginTop }) => {
  const breakpoints = useBreakpoint();

  const slides = items.map(({ logo, text, author }, index) => (
    <SwiperSlide
      className="flex flex-col justify-center pb-16 bg-primary-white pt-14 px-18 xl:pb-12 xl:pt-10 xl:px-14 lg:pb-10 lg:pt-8 lg:px-10 sm:p-6"
      tag="blockquote"
      key={index}
    >
      <div className="flex items-center justify-between sm:flex-col sm:items-start">
        <Image
          className="h-15 sm:mt-5"
          imageSrc={logo.url}
          height={60}
          loading="lazy"
          alt={logo.alt}
          key={index}
        />
        <Quote className="sm:hidden" />
      </div>
      <p className="text-3xl font-normal leading-normal mt-14 xl:text-2xl xl:leading-normal md:text-xl xl:mt-12 lg:mt-10 sm:mt-6">
        {text}
      </p>
      <cite
        className="inline-block mt-6 not-italic font-bold with-link-primary-deep-blue"
        dangerouslySetInnerHTML={{ __html: author }}
      />
    </SwiperSlide>
  ));

  return (
    <section className={classNames('bg-grey-5 py-36 xl:py-24 md:py-14', margins.top[marginTop])}>
      <Container>
        <div className="flex items-end justify-between">
          <Heading className="max-w-[520px] md:max-w-none" tag="h2" size="lg" theme="primary-black">
            {title}
          </Heading>
          <div className="flex justify-center mb-4 space-x-5 text-primary-black md:space-x-7 md:hidden">
            <ArrowButton className="transition-colors duration-200 testimonials-prev-button hover:text-grey-40 md:hidden" />
            <ArrowButton className="transition-colors duration-200 rotate-180 testimonials-next-button hover:text-grey-40 md:hidden" />
          </div>
        </div>
        {!breakpoints.md && (
          <Swiper
            className="mt-11 xl:mt-10"
            modules={[Navigation, A11y, EffectFade]}
            simulateTouch={false}
            effect="fade"
            fadeEffect={{
              crossFade: true,
            }}
            speed={500}
            navigation={{
              prevEl: '.testimonials-prev-button',
              nextEl: '.testimonials-next-button',
            }}
            loop
          >
            {slides}
          </Swiper>
        )}
        {breakpoints.md && (
          <Swiper
            className="mt-10"
            modules={[A11y, Pagination]}
            pagination={{
              type: 'bullets',
              el: '.testimonials-pagination',
              clickable: true,
            }}
            autoHeight
            loop
          >
            {slides}
            <div className="!hidden mt-8 testimonials-pagination slider-bullets md:!flex" />
          </Swiper>
        )}
      </Container>
    </section>
  );
};

Testimonials.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
      }),
      text: PropTypes.string.isRequired,
      author: PropTypes.string.isRequired,
    })
  ).isRequired,
  marginTop: PropTypes.oneOf(Object.keys(margins.top)),
};

Testimonials.defaultProps = {
  marginTop: null,
};

export default Testimonials;
