import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Link from 'components/shared/link';
import useLottie from 'hooks/use-lottie';

import pdfScreenshotScreencastAnimationData from './data/lottie-data-pdf-screenshot-screencast.json';
import scrapingAutomationAnimationData from './data/lottie-data-scraping-automation.json';
import testingAnimationData from './data/lottie-data-testing.json';

const Hero = ({
  title,
  description,
  buttonText,
  buttonUrl,
  linkText,
  linkUrl,
  illustrationName,
}) => {
  const animationData = {
    'scraping-automation': scrapingAutomationAnimationData,
    'pdf-screenshot-screencast': pdfScreenshotScreencastAnimationData,
    testing: testingAnimationData,
  };

  const { animationRef, isAnimationReady, animationVisibilityRef } = useLottie({
    lottieOptions: {
      animationData: animationData[illustrationName],
      loop: true,
    },
    useInViewOptions: {
      threshold: [0.4, 0],
    },
  });

  const illustrationsClassNames = {
    'scraping-automation': {
      wrapper:
        '-top-4 -right-32 w-[674px] h-[468px] 2xl:-right-18 xl:-right-36 lg:w-full lg:h-[347px] sm:h-[265px] xs:h-[calc(100vw-120px)]',
      illustration:
        'w-[850px] xl:w-[610px] lg:-top-15 lg:ml-6 sm:-top-12 sm:w-[500px] xs:-top-9 xs:w-[calc(100vw+60px)] xs:ml-2',
    },
    'pdf-screenshot-screencast': {
      wrapper:
        'top-0 -right-12 w-[638px] h-[448px] 2xl:right-6 xl:-top-8 xl:-right-18 lg:w-full lg:h-[367px] sm:h-[280px] xs:h-[calc(100vw-110px)]',
      illustration:
        'w-[850px] xl:w-[610px] lg:-top-14 lg:-ml-4 sm:-top-11 sm:w-[500px] sm:-ml-2 xs:-top-9 xs:w-[calc(100vw+70px)]',
    },
    testing: {
      wrapper:
        '-top-6 -right-24 w-[727px] h-[468px] 2xl:-right-6 xl:-top-10 xl:-right-32 lg:w-full lg:h-[321px] sm:h-[245px] xs:h-[calc(100vw-135px)]',
      illustration:
        'w-[850px] xl:w-[590px] lg:-top-16 lg:-ml-4 sm:-top-12 sm:w-[450px] sm:ml-0 xs:-top-10 xs:w-[calc(100vw+30px)]',
    },
  };

  return (
    <section
      className="min-h-[616px] bg-primary-black pb-32 pt-18 overflow-hidden xl:pb-24 md:pb-14 xl:min-h-0 xl:pt-14 md:pt-8"
      ref={animationVisibilityRef}
    >
      <Container>
        <Heading
          className="max-w-[550px] xl:max-w-[470px] lg:max-w-none lg:text-center"
          tag="h1"
          size="xl"
          theme="primary-white"
        >
          {title}
        </Heading>
        <p className="relative z-10 mt-7 text-lg text-primary-white max-w-[490px] xl:max-w-[440px] xl:text-base lg:max-w-none lg:text-center sm:mt-5">
          {description}
        </p>
        <div className="flex items-center mt-10 space-x-8 sm:flex-col sm:space-x-0 sm:space-y-6 lg:justify-center">
          <Button to={buttonUrl} size="md" theme="primary-white-filled">
            {buttonText}
          </Button>
          <Link to={linkUrl} target="_blank" size="sm" theme="secondary-blue">
            {linkText}
          </Link>
        </div>
        <div
          className={classNames(
            'absolute lg:relative lg:top-0 lg:right-0 lg:mt-9 lg:mx-auto opacity-0 transition-opacity ease-linear duration-500',
            illustrationsClassNames[illustrationName].wrapper,
            isAnimationReady && 'opacity-100'
          )}
          ref={animationVisibilityRef}
          aria-hidden
        >
          <div
            className={classNames(
              'absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 lg:translate-y-0',
              illustrationsClassNames[illustrationName].illustration
            )}
            ref={animationRef}
          />
        </div>
      </Container>
    </section>
  );
};

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  linkUrl: PropTypes.string.isRequired,
  illustrationName: PropTypes.oneOf(['scraping-automation', 'testing', 'pdf-screenshot-screencast'])
    .isRequired,
};

export default Hero;
