/* eslint-disable react/prop-types */

import classNames from 'classnames';
import noop from 'lodash.noop';
import PropTypes from 'prop-types';
import React from 'react';
import { CopyBlock, monokaiSublime } from 'react-code-blocks';

export const examples = ['screenshot', 'pdf', 'scrape'];

const theme = {
  ...monokaiSublime,
  backgroundColor: 'transparent',
};

const code = {
  screenshot: `import puppeteer from "puppeteer";
  
// Try changing this! 👇
const URL = "https://pptr.dev/";

const main = async () => {
  const browser = await puppeteer.connect({
    browserWSEndpoint: \`wss://chrome.browserless.io\`,
  });
  const page = await browser.newPage();
  await page.goto(URL);

  return page.screenshot();
}

`,
  pdf: `import puppeteer from "puppeteer";
  
// Try changing this! 👇
const URL = "https://react.dev/";

const main = async () => {
  const browser = await puppeteer.connect({
    browserWSEndpoint: \`wss://chrome.browserless.io\`,
  });
  const page = await browser.newPage();
  await page.goto(URL);

  return page.pdf({
    format: 'A5'
  });
}
`,
  scrape: `// scrapes posts from hackernews
import puppeteer from "puppeteer";

// Try changing this! 👇
const keyWord = "";

const main = async () => {
  const browser = await puppeteer.connect({
    browserWSEndpoint: \`wss://chrome.browserless.io\`,
  });
  const page = await browser.newPage();
  await page.goto("https://news.ycombinator.com/");

  const data = await page.evaluate((keyWord) => {
    let $$news = [...document.querySelectorAll(".athing")].filter((elem) =>
      elem.querySelector(".votearrow")
    );
  
    if (keyWord) {
      $$news = $$news.filter(($news) =>
        $news
          .querySelector(".titleline")
          .innerText.toLowerCase()
          .includes(keyWord)
      );
    }
  
    return $$news.map((elem) => {
      const $info = elem.nextElementSibling;
  
      return {
        title: elem.querySelector(".titleline").innerText,
        url: elem.querySelector(".titleline a").href,
        score: parseInt($info.querySelector(".score").innerText),
        date: $info.querySelector("span.age").title,
        by: $info.querySelector(".hnuser").innerText,
      };
    });
  }, keyWord);

  return { data };
};  
`,
};

const Tab = ({ name, isActive, className: additionalClassName, setExample, onTabChange }) => (
  <div
    className={classNames(
      'm-[-1ch] mt-0 ml-4 px-4 py-1 rounded-t cursor-pointer',
      isActive ? 'bg-[#333] text-white' : 'bg-[#232323] text-[#828282]',
      'hover:bg-[#333]',
      additionalClassName
    )}
    key={name}
    role="button"
    tabIndex="0"
    onClick={() => {
      const $editor = document.querySelector('#editorTabs').parentElement;
      const $dots = $editor.nextElementSibling;
      const $result = $dots.nextElementSibling;
      const $button = $editor.parentElement.nextElementSibling;
      const $$busy = [$editor, $dots, $result, $button];

      $$busy.forEach(($el) => $el.classList.add('hero-busy'));
      onTabChange();

      setTimeout(() => {
        setExample(name);
        $$busy.forEach(($el) => $el.classList.remove('hero-busy'));
      }, 500);
    }}
    onKeyDown={noop}
  >
    {name}.js
  </div>
);

const Editor = ({ className: additionalClassName, example, setExample, onTabChange }) => (
  <div
    className={classNames(
      'w-[30rem] h-[30rem] bg-hero-gradient border-2 border-grey-90 rounded !text-left font-mono',
      'xl:ml-[60px] xl:h-[20rem] xl:mt-[-4rem] xl:!mx-0 xl:!my-auto',
      'sm:text-xs xl:text-base',
      'h-[30rem] lg:w-[100%] lg:h-[28rem] xl:h-[30rem] xl:w-[22.5rem] md:h-[20rem]',
      additionalClassName
    )}
  >
    <div className="flex p-2 overflow-x-scroll overflow-y-hidden" id="editorTabs">
      <div className="flex items-center">
        <div className="bg-grey-90 w-3 h-3 rounded-full" />
        <div className="bg-grey-90 w-3 h-3 rounded-full !ml-2" />
        <div className="bg-grey-90 w-3 h-3 rounded-full !ml-2" />
      </div>

      {examples.map((name) => {
        const isActive = name === example;
        return (
          <Tab
            name={name}
            key={name}
            setExample={setExample}
            isActive={isActive}
            onTabChange={onTabChange}
          />
        );
      })}
    </div>
    <hr className="bg-grey-90 border-none h-[2px]" />
    <div
      id="codeWrapper"
      className="h-[30rem] max-h-[30rem] xl:h-[28rem] lg:h-[23rem] md:h-[20rem]"
    >
      <CopyBlock
        text={code[example]}
        language="js"
        theme={theme}
        showLineNumbers={false}
        codeBlock
        wrapLines
      />
    </div>
  </div>
);

Editor.propTypes = {
  className: PropTypes.string,
  example: PropTypes.oneOf([...examples]),
  onTabChange: PropTypes.func,
  setExample: PropTypes.func,
};

Editor.defaultProps = {
  className: '',
  example: examples[0],
  onTabChange: noop,
  setExample: noop,
};

export default Editor;
