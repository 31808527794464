export default [
  {
    title: 'Starter',
    planType: '180k',
    price: '200',
    note: 'Process thousands of pages in our cloud',
    buttonText: 'Start 7 Day Trial',
    buttonUrl: '/sign-up-email',
    buttonTheme: 'primary-black-filled',
    items: [
      '180k units per month',
      '25 max concurrent browsers',
      '10GB of residential proxy at 6 units/MB',
      '$0.0017 per unit overages',
      '$5 per extra concurrency',
      'Multi browser support',
      'Captcha bypassing',
      'Email support',
    ],
    theme: 'primary-white',
    isPopular: false,
    isEnterprise: false,
  },
  {
    title: 'Scale',
    price: '500',
    planType: '500k',
    note: 'Scale your automations with extra concurrency and pages',
    buttonText: 'Start 7 Day Trial',
    buttonUrl: '/sign-up-email',
    buttonTheme: 'primary-black-filled',
    items: [
      '500k units per month',
      '50 max concurrent browsers',
      '25GB of residential proxy at 6 units/MB ',
      '$0.0015 per unit overages',
      '$5 per extra concurrency',
      'Multi browser support',
      'Captcha bypassing',
      'Email support',
    ],
    theme: 'primary-white',
    isPopular: true,
    isEnterprise: false,
  },
  {
    title: 'Enterprise',
    note: 'Dedicated private cloud or licensed self-hosted deployments',
    price: 'Custom',
    buttonText: 'Contact Sales',
    buttonUrl: '/contact',
    buttonTheme: 'primary-white-filled',
    items: [
      `Millions of units in a private server or in your infrastructure`,
      `100s or 1,000s of concurrent browsers`,
      `Custom proxy limits with geotargeting`,
      `Datacenter location options, incl. EU`,
      `Persistent cache and cookies`,
      `Keep browsers warm for reconnecting`,
      `Manage downloaded files`,
      `GPU enabled infrastructure`,
      `SSO, tokens and user roles`,
      `Extendable codebase for building your own custom features`,
      `Commercial license if self-hosting our container`,
      `Video support and setup assistance`,
      `Dedicated Technical Account Manager`,
    ],
    theme: 'primary-black',
    isPopular: false,
    isEnterprise: true,
  },
];
