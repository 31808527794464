import { motion, useAnimation } from 'framer-motion';
import { useStaticQuery, graphql } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import Button from 'components/shared/button';
import Container from 'components/shared/container';
import Heading from 'components/shared/heading';
import Image from 'components/shared/image';
import Link from 'components/shared/link';

import cardItem1Illustration from './images/card-item-1-illustration.svg';
import cardItem2Illustration from './images/card-item-2-illustration.svg';
import cardItem3Illustration from './images/card-item-3-illustration.svg';

const Features = ({ title, listItems, cardItems }) => {
  const { cardItem1IllustrationAsset1, cardItem2IllustrationAsset1 } = useStaticQuery(graphql`
    query {
      cardItem1IllustrationAsset1: file(
        relativePath: { eq: "pages/home/features/card-item-1-illustration-asset-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 112)
        }
      }
      cardItem2IllustrationAsset1: file(
        relativePath: { eq: "pages/home/features/card-item-2-illustration-asset-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 144)
        }
      }
    }
  `);

  const cardItemImagesProps = [
    {
      width: 320,
      height: 282,
      imageSrc: cardItem1Illustration,
      gatsbyImage: cardItem1IllustrationAsset1,
      gatsbyImageWidth: 112,
      gatsbyImageHeight: 112,
      gatsbyImageX: 165,
      gatsbyImageY: 159,
    },
    {
      width: 320,
      height: 282,
      imageSrc: cardItem2Illustration,
      gatsbyImage: cardItem2IllustrationAsset1,
      gatsbyImageWidth: 144,
      gatsbyImageHeight: 86,
      gatsbyImageX: 88,
      gatsbyImageY: 52,
    },
    {
      width: 320,
      height: 282,
      imageSrc: cardItem3Illustration,
    },
  ];

  const breakpoints = useBreakpoint();

  const cardItemsUlVariants = {
    to: {
      transition: {
        staggerChildren: 0.3,
        staggerDirection: breakpoints.md ? undefined : -1,
      },
    },
  };

  const cardItemsLiVariants = {
    from: {
      translateY: '100%',
    },
    to: {
      translateY: '0%',
      transition: {
        ease: [0.25, 0.1, 0, 1],
        duration: 0.5,
      },
    },
  };

  const cardItemsCurtainVariants = {
    from: {
      translateY: '0%',
    },
    to: {
      translateY: '-101%',
      transition: {
        ease: [0.25, 0.1, 0, 1],
        duration: 0.75,
      },
    },
  };

  const [animationVisibilityRef, isInView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start('to');
    }
  }, [isInView, controls]);

  return (
    <section className="bg-grey-5 py-36 xl:py-24 md:py-14">
      <Container>
        <Heading
          className="max-w-[1008px] xl:max-w-[945px]"
          tag="h2"
          size="lg"
          theme="primary-black"
        >
          {title}
        </Heading>
        <ul className="grid grid-cols-3 mt-20 gap-y-20 gap-x-8 xl:mt-16 xl:gap-x-7 xl:gap-y-16 lg:grid-cols-2 lg:gap-x-4 md:block md:space-y-5 md:mt-9">
          {listItems.map(({ text }, index) => (
            <li
              className="relative text-lg font-semibold pl-5 before:absolute before:top-3 before:left-0 before:block before:w-1.5 before:h-1.5 before:bg-primary-black with-link-primary-black xl:text-base xl:before:top-2"
              key={index}
            >
              <p
                className="max-w-[292px] xl:max-w-[256px] lg:max-w-none"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </li>
          ))}
        </ul>
        <motion.ul
          className="grid grid-cols-3 mt-20 overflow-hidden text-center gap-x-8 xl:mt-20 xl:gap-x-7 lg:gap-x-4 md:block md:mt-14 md:space-y-4"
          variants={cardItemsUlVariants}
          initial="from"
          animate={controls}
          ref={animationVisibilityRef}
        >
          {cardItems.map(({ title, url }, index) => (
            <motion.li
              className="relative px-8 pt-5 overflow-hidden border pb-11 bg-primary-white border-grey-20 xl:px-5 xl:pt-5 xl:pb-8 lg:px-4"
              variants={cardItemsLiVariants}
              key={index}
            >
              <Link className="block group" to={url}>
                <Image
                  className="w-full md:max-w-[250px] md:mx-auto"
                  {...cardItemImagesProps[index]}
                />
                <Heading
                  className="mt-8 xl:mt-4 lg:max-w-[118px] lg:mx-auto md:max-w-none"
                  tag="h3"
                  size="md"
                  theme="primary-black"
                >
                  {title}
                </Heading>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  className="mt-6 xl:mt-4"
                  tag="span"
                  size="md"
                  theme="primary-deep-blue"
                  withArrow
                >
                  Learn more
                </Link>
                <motion.div
                  className="absolute top-0 -left-px -right-px h-[200%]"
                  style={{ background: 'linear-gradient(225deg, #ff3 0%, #f39 100%)' }}
                  variants={cardItemsCurtainVariants}
                  aria-hidden
                />
              </Link>
            </motion.li>
          ))}
        </motion.ul>

        <div className="flex justify-center gap-4">
          <Button
            className="mt-9 lg:mt-6 lg:mb-14 sm:px-12"
            to="/sign-up"
            size="md"
            theme="primary-black-filled"
          >
            Start For Free
          </Button>
          <Button
            className="mt-9 lg:mt-6 lg:mb-14 sm:px-12"
            to="/contact"
            size="md"
            theme="primary-black-outline"
          >
            Talk to us
          </Button>
        </div>
      </Container>
    </section>
  );
};

Features.propTypes = {
  title: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  cardItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Features;
